var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-account-tie-outline","title":"Proveedores registrados","color":"orange"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.providers,"loading-text":"Cargando... Por favor, espere","loading":_vm.fetchingProviders,"no-data-text":"No hay datos disponibles","footer-props":{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('br'),_c('v-row',{attrs:{"justify":"center","justify-md":"end"}},[_c('v-col',{attrs:{"cols":"3","md":"1"}},[_c('v-fab-transition',[_c('v-btn',{staticClass:"v-btn--example",attrs:{"color":"orange","fab":"","large":""},on:{"click":function($event){_vm.dialogA = true}}},[_c('v-icon',[_vm._v(" mdi-account-plus ")])],1)],1)],1)],1)]},proxy:true},{key:"item.enable",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.enable)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"},on:{"click":function($event){return _vm.deleteUser(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):(!item.enable)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){return _vm.enableUser(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.enable ? 'Presione para desabilitar' : 'Presione para habilitar'))])])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","fab":"","dark":"","small":"","color":"info"},on:{"click":function($event){return _vm.openEditModal(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1),(item.enable)?_c('v-btn',{attrs:{"text":"","fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.openDeleteModal(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1):_c('v-btn',{attrs:{"text":"","fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.activate(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-check-all ")])],1)]}}],null,true)})],1)],1),_c('form-dialog-add',{attrs:{"dialog":_vm.dialogA},on:{"close":function($event){_vm.dialogA = false}}}),_c('form-dialog-edit',{attrs:{"edit-user":_vm.editUser,"dialog":_vm.dialogE},on:{"close":function($event){_vm.dialogE = false}}}),_c('accept-dialog',{attrs:{"dialog":_vm.dialogD,"item":_vm.deleteItem},on:{"close":function($event){_vm.dialogD = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }